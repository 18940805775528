/*** 
    COLORS
***/

// Primary
@color-blue: #1996FF;
@color-purple: #6E1EAA;

// Secondary
@color-navy: #2B55B4;

// Information
@color-green: #3CAA32;

// Warnings
@color-orange: #FF8200;

// Text / UI-elements
@color-black: #000;
@color-text: fade(@color-black, 70%);

// Backgrounds
@color-gray: #EBEBEB; 
@color-lightgray: #F7F9FA;
@color-white: #fff;

@font-family-gotham: Gotham Rounded A, Gotham Rounded B, 'GothamRounded', sans-serif;

@font-face {
    font-family: 'GothamRounded';
    src: url("https://pages.wellspect.com/hubfs/Assets/typefaces/GothamRounded-Light.otf") format("opentype");
}

// Increase specificity by one element to trump the standard specificity.
body {
    background: #EBEBEB !important;

    &.subscription-page .page-header h2 {
        background: @color-blue;
    }

    &.subscription-page .header {
        background: none;
    }

    a {
        color: @color-blue;
    }

    .header .full-screen-width {
        background: @color-blue;
    }

    .notify-bar.cookie-information {
        background: fade(@color-blue, 70%);
    }

    .notify-bar a {
        color: @color-white;
    }

    .notify-bar.us-information {
        background: @color-green;
        color: @color-white;
    }

    .notify-bar .accept-link,
    .notify-bar .accept-link:visited,
    .notify-bar .accept-link:hover,
    .notify-bar .accept-link:focus {
        color: @color-white;
    }

    .notify-bar .accept-link {
        top: 50%;
        transform: translateY(-50%) scaleX(1.6);
        font-weight: 100;
    }

    .wellspect-header .second-row .logo .ico {
        background: url(https://pages.wellspect.com/hubfs/Assets/img/WellspectLogo.svg) no-repeat;
        height: 35px;
        width: 153px;
        margin-top: 14px;
        background-size: contain;
        transform: translateY(1px);
    }

    .header {
        border: none;
        background: #f7f9fa;
    }

    .header .main-menu a,
    .header .main-menu a:visited,
    .header .main-menu a:hover,
    .header .main-menu a:focus {
        color: @color-purple !important;
        font-weight: 600;
        font-size: 1.6em !important;
    }

    .header .main-menu li.active {
        background: @color-purple;
        border-radius: 15px;

        a {
            color: @color-white !important;
        }
    }

    @media (min-width: 768px) {
        .header .main-menu ul li {
            padding: .4em 2.5em;
        }
    }

    .more-link,
    .hs-button,
    .btn,
    button,
    input[type="button"],
    input[type="submit"] {
        background: @color-blue;
        color: @color-white;
        border-radius: 15px;
        padding: 11px 30px;
        text-decoration: none;
        font-size: 18px;
        font-weight: 600;

        &:hover, &:focus {
            background: fade(@color-blue, 70%);
            outline: none;
        }

        &:active {
            background: fade(@color-blue, 40%);
            color: @color-blue;
        }
    }

    .post-body img {
        border-top-left-radius: 30px;
    }

    .footer {
        border-top: 1px solid #c7c7c7;

        @media (min-width: 768px) {
            padding-top: 30px;
        }
    }

    .footer .full-screen-width.background-lightblue {
        background: @color-gray;
    }

    .footer,
    .footer .footer-section ul li a,
    .footer .footer-section ul li a:visited,
    .widget a,
    .widget a:visited {
        color: @color-blue;
    }

    .footer .footer-copyright-bar {
        background: @color-blue;
        padding: 5px 0;
    }

    .footer .bottom-row .widget.social-icons div {
        background: @color-white;
    }

    .footer__socialwrapper {
        background: @color-white;
        margin-left: 1em;
        display: inline-block;
        border-radius: 20px;
        padding: 10px;

        div {
            display: flex;
            flex-wrap: wrap;
        }

        a {
            font-size: 0;
            margin-top: 2px;
            margin-bottom: 2px;

            &:not(:last-child) {
                margin-right: 5px;
            }
        }

        img {
            width: 32px;
        }
    }

    .footer .logo .ico {
        background: url(https://pages.wellspect.com/hubfs/Assets/img/WellspectLogo.svg) no-repeat !important;
        width: 130px !important;
        height: 30px !important;
    }

    .footer.no-print .toplogo {
        display: none;
        width: auto;


        a {
            display: inline-block;
            margin-left: 1em;
            margin-top: 2em;
        }

        .ico {
            position: static;
        }

        @media (min-width: 768px) {
            display: block;
        }
    }

    .footer.no-print .bottomlogo {
        @media (min-width: 768px) {
            display: none;
        }
    }

    @media (max-width: 767px) {
        .footer .bottom-row {
            background: @color-white !important;
            padding-top: 0 !important;
            padding-bottom: 10px;
        }
    }

    @media (max-width: 481px) {

        .footer .footer-section.expandable {
            border-bottom: 1px solid @color-blue;
        }

        .footer .footer-section.expandable > p.expand::after {
            background-image: url(https://pages.wellspect.com/hubfs/Assets/img/DropdownArrow.svg);
            border: none;
            width: 12px;
            height: 7px;
            transform: rotate(-90deg);
            right: 10px;
            top: 10px;
        }

        .footer .footer-section.expandable > p.expanded::after {
            background-image: url(https://pages.wellspect.com/hubfs/Assets/img/DropdownArrow.svg);
            border: none;
            width: 12px;
            height: 7px;
            right: 10px;
            top: 10px;
            border: none;
            transform: rotate(0);
        }
    }

    .footer .wave {
        display: none;
    }

    .wellspect-footer .page-width p,
    .wellspect-footer .page-width .footer-header {
        color: @color-blue;
    }

    .wellspect-footer .full-screen-width {
        border-top: none;
    }

    .footer.new {
        display: block !important;
    }

    .footer.old {
        display: none;
    }

    .wellspect-footer .footer-copyright-bar p {
        color: @color-white;
    }

    .wellspect-header .full-screen-width .page-width {
        border: none;
    }

    .wellspect-header .responsive-logo .ico {
        background: url(https://pages.wellspect.com/hubfs/Assets/img/WellspectLogo.svg) no-repeat !important;
        width: 130px;
        height: 30px;
        background-size: auto;
        background-position: inherit;
    }

    .wellspect-header .responsive-logo {
        margin-top: 16px;
    }

    .toplink a p {
        -webkit-font-smoothing: subpixel-antialiased;
    }

    .toplink .ico {
        background: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png) no-repeat;
        background-size: 1108px 513px;
    }

    .toplink.contact-us-ico .ico {
        background-position: -323px -306px;
    }

    .toplink.about-us-ico .ico {
        background-position: -188px -306px;
        top: 4px;
        margin-right: 6px;
    }

    @media (max-width: 767px) {

        .header .toplinks {
            top: 18px;
            position: relative;
        }

        .header .toplinks .toplink {
            line-height: 0;
            margin-left: 5px;

            a {
                width: 20px;
                height: 20px;
                padding: 0;
                display: inline-block;
            }
        }

        .header .toplinks .contact-us-ico .ico {
            background: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png) no-repeat;
            background-size: 1108px 513px;
            background-position: -323px -326px;
        }

        .header .toplinks .about-us-ico .ico {
            background: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png) no-repeat;
            background-size: 1108px 513px;
            background-position: -188px -326px;
            top: 2px;
        }
    }

    .wellspect-header .toggle-menu .ico {
        background: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png) no-repeat;
        background-size: 1108px 513px;
        background-position: -525px -327px;
    }

    @media (max-width: 767px) {
        .header .full-screen-width {
            background-color: #F7F9FA !important;
        }
    }

    .line-decoration {
        width: 100%;
        height: 20px;
        border-top: 2px solid white;
        border-left: 2px solid white;
        border-top-left-radius: 20px;
    }

    #blogHeader {
        background-image: url(https://pages.wellspect.com/hubfs/Assets/img/dave.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center top;
        height: 250px;
        padding: 30px 20px;

        @media (min-width: 1024px) {
            height: 350px;
        }

        h1, h2 {
            font-family: @font-family-gotham !important;
            font-size: 40px;
            color: @color-white;
            font-weight: 100;
            text-transform: uppercase;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;

            @media (min-width: 1024px) {
                font-size: 65px;
                max-width: 50%;
            }
        }

        > div {
            background: none;
            padding: 0;
            position: relative;
            height: 100%;
        }

        .widget-span {
            padding: 0 !important;
        }
    }

    .sidebar {
        .widget-type-blog_subscribe {
            input[type=submit] {
                background: @color-purple;

                &:hover, &:focus {
                    background: fade(@color-purple, 70%);
                }

                &:active {
                    background: fade(@color-purple, 40%);
                    color: @color-purple;
                }
            }
        }
    }

    #hs-search-module {
        label {
            margin: 0 0 .55em;
            font-size: 16px;
            font-weight: 400;
            color: #242424;
        }

        .input {
            a {
                height: 100%;
                width: 40px;
                opacity: 0;
                z-index: 2;
            }

            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 10px;
                width: 16px;
                height: 16px;
                background-image: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png);
                background-size: 1108px 513px;
                display: inline-block;
                background-position: -780px -325px;
                z-index: 1;
            }
        }
    }

    #hs-search-module .input input, #hs-search-module .input a {
        height: auto;
    }
    /* FORM */
    .hs-form .hs-input {
        width: 100% !important;
        border-radius: 10px;
        border: 1px solid fade(@color-black, 20%);
        background: @color-white;
        padding: 5px 20px;
        line-height: 2.083333333em;
        height: auto;

        &:focus {
            outline: none;
            border-color: @color-navy;
        }
    }

    .hs-form select.hs-input {
        padding: 5px 20px;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url(https://pages.wellspect.com/hubfs/Assets/img/DropdownArrow.svg);
        background-repeat: no-repeat;
        background-position: calc(~"100% - 15px") center;
        box-sizing: content-box;

        &::-ms-expand {
            display: none;
        }
    }

    .hs-form-field label {
        font-weight: 800;
        color: @color-text;
    }

    .hs-form {

        .hs-error-msgs, .hs-error-msgs * {
            color: @color-orange;
        }

        .hs-form-required {
            color: @color-text;
        }

        .hs-form-radio-display,
        .hs-form-booleancheckbox-display,
        .hs-form-booleancheckbox label,
        .hs-form-radio label,
        .hs-form-checkbox-display {
            padding-left: 30px;
        }

        select {
            box-sizing: border-box !important;
        }

        input[type=checkbox], input[type=radio] {
            display: none !important;

            & + span {
                position: relative;
                //margin-left: 30px;
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: -30px;
                    width: 20px;
                    height: 20px;
                    background-color: @color-white;
                    border: 1px solid fade(@color-black, 20%);
                }
            }

            &:checked {
                & + span {
                    &::before {
                        background-image: url(https://pages.wellspect.com/hubfs/Assets/img/CheckMark.svg);
                        background-repeat: no-repeat;
                        background-size: 90%;
                        background-position: center;
                    }
                }
            }
        }

        input[type=radio] {

            & + span {
                &::before {
                    border-radius: 50%;
                }
            }

            &:checked {

                & + span {

                    &::before {
                        background: @color-white;
                    }

                    &::after {
                        content: '';
                        width: 12px;
                        height: 12px;
                        background: @color-blue;
                        position: absolute;
                        border-radius: 50%;
                        top: 4px;
                        left: -26px;
                    }
                }
            }
        }
    }
    /* Mobile menu */
    .header .wrap {

        .resp-main-menu {
            overflow: hidden;
        }

        .resp-main-menu ul {
            padding: 0;
            margin: 0;
            width: 100%;
        }

        .resp-main-menu ul a {
            padding: 0;
            margin: 0;
            color: #A0A0A0;
            width: 100%;
            background-color: #f0f0f0;
        }

        .resp-main-menu ul li {
            padding: 0;
            margin: 0;
            display: block;
            width: 100%;
            *zoom: 1;
        }

        .resp-main-menu ul li:before,
        .resp-main-menu ul li:after {
            display: table;
            content: "";
            line-height: 0;
        }

        .resp-main-menu ul li:after {
            clear: both;
        }

        .resp-main-menu ul li a {
            text-decoration: none;
        }

        .resp-main-menu ul li a:visited {
            text-decoration: none;
        }

        .resp-main-menu ul li a:hover {
            text-decoration: none;
            text-decoration: underline;
        }

        .resp-main-menu ul li a:active {
            text-decoration: none;
        }

        .resp-main-menu ul li a {
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            display: inline-block;
            vertical-align: top;
            width: 100%;
        }

        .resp-main-menu ul li a.link {
            font-size: 12px;
            display: block;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;
            min-height: 40px;
            padding-left: 1em;
            padding-right: 30px;
        }

        .resp-main-menu ul li a.expand-link {
            font-size: 1.2em;
            display: block;
            position: absolute;
            top: 6px;
            width: 35px;
            right: 0px;
            height: 25px;
            padding-left: 30px !important;
            -moz-box-sizing: border-box;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
        }

        .resp-main-menu ul li a.expand-link:after {
            border: solid transparent;
            content: " ";
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-width: 8px;
            border-left-color: #646464;
            right: 5px;
            top: 5px;
        }

        .resp-main-menu ul li a.expand-link.expanded:after {
            border: solid transparent;
            content: " ";
            position: absolute;
            pointer-events: none;
            border-color: rgba(255, 255, 255, 0);
            border-width: 8px;
            border-top-color: #646464;
            right: 10px;
            top: 10px;
        }

        .resp-main-menu ul li.active > a {
            font-weight: bolder;
        }

        .resp-main-menu ul li.has-children {
            position: relative;
        }

        .resp-main-menu ul li.has-children > ul {
            display: none;
        }

        .resp-main-menu ul li.has-children.expanded {
            position: relative;
        }

        .resp-main-menu > ul {
            background: #e7e8e9;
            margin-bottom: -2px;
        }


        .resp-main-menu li.activeSection a {
            border-left: 4px solid #0060a9;
            padding-left: 1em;
            color: #646464;
            background-color: #e7e8e9;
        }


        .resp-main-menu > ul > li.active.expanded > a, .resp-main-menu > ul > li.active > a {
            border-left: 4px solid #bdbf00;
        }

        .resp-main-menu > ul > li li.active > a {
            border-left: 4px solid #bdbf00;
        }
        /*No border on the arrow link box */
        .resp-main-menu li.activeSection a.expand-link, .resp-main-menu > ul > li li.active > a.resp-main-menu, .resp-main-menu ul.level-1 li a.expand-link {
            border-left: 0px solid #646464;
        }

        .resp-main-menu > ul li a.link:after {
            content: "";
            height: 1px;
            background-color: #cfd1d3;
            display: block;
            width: 110%;
            position: relative;
            top: 12px;
        }

        .desk-main-menu, .resp-main-menu {
            ul a {
                background-color: @color-white;
            }

            li.activeSection a {
                border-left: 4px solid fade(@color-purple, 20%);
            }

            > ul > li li.active > a,
            > ul > li.active.expanded > a,
            > ul > li.active > a {
                border-left-color: @color-purple;
            }

            > ul li a.link:after {
                top: 12px;
            }

            li {
                line-height: inherit !important;
            }

            a.link {
                border-bottom: none !important;
                line-height: inherit !important;
            }

            ul li a.expand-link::after {
                background-image: url(https://pages.wellspect.com/hubfs/Assets/img/DropdownArrow.svg);
                border: none;
                width: 12px;
                height: 7px;
                transform: rotate(-90deg);
                right: 10px;
                top: 10px;
            }

            ul li a.expand-link.expanded::after {
                border: none;
                transform: rotate(0);
            }

            .expand-link {
                &:focus {
                    outline: none;
                }
            }

            ul a,
            li.activeSection a {
                color: @color-text;
            }
        }
    }

    @media (max-width: 767px) {
        .header .toggle-menu.active {
            background: none;
        }

        .header .toggle-menu.active::after {
            border-bottom-color: @color-blue;
        }
    }

    &.landing-page .header-container .widget-type-logo,
    &.landing-page .header-container .hs_cos_wrapper_type_linked_image {
        a {
            background: url(https://pages.wellspect.com/hubfs/Assets/img/WellspectLogo.svg) no-repeat;
            height: 35px;
            width: 153px;
            background-size: contain;
            display: inline-block;
            margin-left: 15px;
            transform: translateY(7px);

            @media (min-width: 1085px) {
                margin-left: 0;
            }

            @media (min-width: 992px) {
                transform: translateY(5px);
                margin-top: 10px;
            }
        }

        img {
            display: none;
        }
    }

    &.landing-page.blue-bar {
        border-top-color: @color-blue;

        @media (max-width: 991px) {
            border-top: none;
        }
    }

    &.landing-page .header-container-wrapper {
        border-bottom: none;
        background: #f7f9fa;
    }

    blockquote {
        border-left: 10px solid #ccc;
        margin: 1.5em 5px;
        padding: .5em 10px;
        font-size: 1.3em;

        &::before {
            color: #ccc;
            content: "\201C";
            font-size: 3em;
            line-height: .3em;
            vertical-align: 0;
            display: block;
            font-family: Georgia,serif;
        }
    }


    .cta_button {
        display: inline-block;
        //background: @color-blue !important;
        //color: @color-white !important;
        border-radius: 15px !important;
        padding: 11px 30px !important;
        text-decoration: none !important;
        box-sizing: border-box !important;

        &, * {
            font-size: 18px !important;
            font-weight: 600 !important;
        }

        //&:hover, &:focus {
        //    background: fade(@color-blue, 70%) !important;
        //}
        //
        //&:active {
        //    background: fade(@color-blue, 40%) !important;
        //    color: @color-blue !important;
        //
        //    span {
        //        color: @color-blue !important;
        //    }
        //}

        p,
        img {
            margin: 0;
            padding: 0;
        }

        //span {
        //    color: @color-white !important;
        //    font-weight: 600 !important;
        //    font-size: 14px !important;
        //}
        // Uuuugly hack to reset styling when linking to play/app store
        &[cta_dest_link*="play.google.com"],
        &[cta_dest_link*="itunes.apple.com"] {
            background: none !important;
            padding: 0 !important;
        }
    }

    //.button--purple {
    //    background: @color-purple !important;
    //    color: @color-white !important;
    //    //border-radius: 15px !important;
    //    //padding: 11px 30px !important;
    //    //text-decoration: none !important;
    //    //box-sizing: border-box !important;
    //    //font-weight: 600 !important;
    //    //font-size: 14px !important;
    //
    //    &:hover, &:focus {
    //        background: fade(@color-purple, 70%) !important;
    //    }
    //
    //    &:active {
    //        background: fade(@color-purple, 40%) !important;
    //        color: @color-purple !important;
    //
    //        span {
    //            color: @color-purple !important;
    //        }
    //    }
    //
    //    //span {
    //    //    color: @color-white !important;
    //    //    font-weight: 600 !important;
    //    //    font-size: 14px !important;
    //    //}
    //}

    table.product-list .product-ui__icon--add {
        background-image: url(https://pages.wellspect.com/hubfs/Assets/img/icon-add-filled.svg);
    }

    table.product-list .product-ui__icon--subtract {
        background-image: url(https://pages.wellspect.com/hubfs/Assets/img/icon-subtract-filled.svg);
    }

    #blogHeader h1 a:hover, #blogHeader h2 a:hover {
        text-decoration: none;
        color: @color-gray;
    }

    .widget-type-linked_image img,
    .widget-type-rich_text img {
        border-top-left-radius: 30px;
    }

    @media (max-width: 767px) {
        .second-row {
            display: block;
        }

        .header .resp-main-menu ul li.has-children.expanded {
            background: transparent;
        }

        .resp-main-menu li.activeSection a {
            background-color: #e7e8e9;
        }

        .header .resp-main-menu ul.level-0 li > a {
            background: #fff;
        }
    }
}

body {
    overflow-x: hidden;
}

.full-hero {
    min-height: 200px;
    position: relative;
    overflow: hidden;
    margin-bottom: 55px;
    margin-left: calc(~"-50vw + 50%");
    margin-right: calc(~"-50vw + 50%");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;

    @media (min-width: 768px) {
        margin-top: -40px;
    }


    * {
        box-sizing: inherit;
    }

    &__innerwrapper {
        max-width: 1024px;
        margin: 0 auto;
        width: 100%;
        position: relative;
        padding: 0 20px 50px 0;

        @media (min-width: 1024px) {
            padding: 0 10px 55px;
        }
    }

    .line-decoration {
        margin-top: 25px;

        @media (min-width: 1024px) {
            margin-top: 30px;
        }
    }

    &__textwrapper {
        margin-left: 20px;

        @media (min-width: 768px) {
            max-width: 50%;
        }
    }

    &__text {
        margin: 35px 0 40px 0;
        font-family: @font-family-gotham !important;
        font-size: 40px;
        line-height: 1.2em;
        font-weight: 100;
        text-transform: uppercase;
        color: @color-white;

        @media (min-width: 1024px) {
            font-size: 65px;
        }
    }

    &__image {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .button {
        margin-left: 20px;

        @media (max-width: 500px) {
            bottom: -60px;
            position: absolute;
            width: 100%;
            margin: 0;
            background: @color-blue;
            border: none;
            text-align: center;

            &:hover, &:focus {
                color: @color-white !important;
                background: fade(@color-blue, 70%);
            }

            &:active {
                background: fade(@color-blue, 40%);
                color: @color-blue !important;
            }
        }
    }

  
}
