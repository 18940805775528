/*** 
    COLORS
***/
@font-face {
  font-family: 'GothamRounded';
  src: url("https://pages.wellspect.com/hubfs/Assets/typefaces/GothamRounded-Light.otf") format("opentype");
}
body {
  background: #EBEBEB !important;
  /* FORM */
  /* Mobile menu */
}
body.subscription-page .page-header h2 {
  background: #1996FF;
}
body.subscription-page .header {
  background: none;
}
body a {
  color: #1996FF;
}
body .header .full-screen-width {
  background: #1996FF;
}
body .notify-bar.cookie-information {
  background: rgba(25, 150, 255, 0.7);
}
body .notify-bar a {
  color: #fff;
}
body .notify-bar.us-information {
  background: #3CAA32;
  color: #fff;
}
body .notify-bar .accept-link,
body .notify-bar .accept-link:visited,
body .notify-bar .accept-link:hover,
body .notify-bar .accept-link:focus {
  color: #fff;
}
body .notify-bar .accept-link {
  top: 50%;
  transform: translateY(-50%) scaleX(1.6);
  font-weight: 100;
}
body .wellspect-header .second-row .logo .ico {
  background: url(https://pages.wellspect.com/hubfs/Assets/img/WellspectLogo.svg) no-repeat;
  height: 35px;
  width: 153px;
  margin-top: 14px;
  background-size: contain;
  transform: translateY(1px);
}
body .header {
  border: none;
  background: #f7f9fa;
}
body .header .main-menu a,
body .header .main-menu a:visited,
body .header .main-menu a:hover,
body .header .main-menu a:focus {
  color: #6E1EAA !important;
  font-weight: 600;
  font-size: 1.6em !important;
}
body .header .main-menu li.active {
  background: #6E1EAA;
  border-radius: 15px;
}
body .header .main-menu li.active a {
  color: #fff !important;
}
@media (min-width: 768px) {
  body .header .main-menu ul li {
    padding: .4em 2.5em;
  }
}
body .more-link,
body .hs-button,
body .btn,
body button,
body input[type="button"],
body input[type="submit"] {
  background: #1996FF;
  color: #fff;
  border-radius: 15px;
  padding: 11px 30px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}
body .more-link:hover,
body .hs-button:hover,
body .btn:hover,
body button:hover,
body input[type="button"]:hover,
body input[type="submit"]:hover,
body .more-link:focus,
body .hs-button:focus,
body .btn:focus,
body button:focus,
body input[type="button"]:focus,
body input[type="submit"]:focus {
  background: rgba(25, 150, 255, 0.7);
  outline: none;
}
body .more-link:active,
body .hs-button:active,
body .btn:active,
body button:active,
body input[type="button"]:active,
body input[type="submit"]:active {
  background: rgba(25, 150, 255, 0.4);
  color: #1996FF;
}
body .post-body img {
  border-top-left-radius: 30px;
}
body .footer {
  border-top: 1px solid #c7c7c7;
}
@media (min-width: 768px) {
  body .footer {
    padding-top: 30px;
  }
}
body .footer .full-screen-width.background-lightblue {
  background: #EBEBEB;
}
body .footer,
body .footer .footer-section ul li a,
body .footer .footer-section ul li a:visited,
body .widget a,
body .widget a:visited {
  color: #1996FF;
}
body .footer .footer-copyright-bar {
  background: #1996FF;
  padding: 5px 0;
}
body .footer .bottom-row .widget.social-icons div {
  background: #fff;
}
body .footer__socialwrapper {
  background: #fff;
  margin-left: 1em;
  display: inline-block;
  border-radius: 20px;
  padding: 10px;
}
body .footer__socialwrapper div {
  display: flex;
  flex-wrap: wrap;
}
body .footer__socialwrapper a {
  font-size: 0;
  margin-top: 2px;
  margin-bottom: 2px;
}
body .footer__socialwrapper a:not(:last-child) {
  margin-right: 5px;
}
body .footer__socialwrapper img {
  width: 32px;
}
body .footer .logo .ico {
  background: url(https://pages.wellspect.com/hubfs/Assets/img/WellspectLogo.svg) no-repeat !important;
  width: 130px !important;
  height: 30px !important;
}
body .footer.no-print .toplogo {
  display: none;
  width: auto;
}
body .footer.no-print .toplogo a {
  display: inline-block;
  margin-left: 1em;
  margin-top: 2em;
}
body .footer.no-print .toplogo .ico {
  position: static;
}
@media (min-width: 768px) {
  body .footer.no-print .toplogo {
    display: block;
  }
}
@media (min-width: 768px) {
  body .footer.no-print .bottomlogo {
    display: none;
  }
}
@media (max-width: 767px) {
  body .footer .bottom-row {
    background: #fff !important;
    padding-top: 0 !important;
    padding-bottom: 10px;
  }
}
@media (max-width: 481px) {
  body .footer .footer-section.expandable {
    border-bottom: 1px solid #1996FF;
  }
  body .footer .footer-section.expandable > p.expand::after {
    background-image: url(https://pages.wellspect.com/hubfs/Assets/img/DropdownArrow.svg);
    border: none;
    width: 12px;
    height: 7px;
    transform: rotate(-90deg);
    right: 10px;
    top: 10px;
  }
  body .footer .footer-section.expandable > p.expanded::after {
    background-image: url(https://pages.wellspect.com/hubfs/Assets/img/DropdownArrow.svg);
    width: 12px;
    height: 7px;
    right: 10px;
    top: 10px;
    border: none;
    transform: rotate(0);
  }
}
body .footer .wave {
  display: none;
}
body .wellspect-footer .page-width p,
body .wellspect-footer .page-width .footer-header {
  color: #1996FF;
}
body .wellspect-footer .full-screen-width {
  border-top: none;
}
body .footer.new {
  display: block !important;
}
body .footer.old {
  display: none;
}
body .wellspect-footer .footer-copyright-bar p {
  color: #fff;
}
body .wellspect-header .full-screen-width .page-width {
  border: none;
}
body .wellspect-header .responsive-logo .ico {
  background: url(https://pages.wellspect.com/hubfs/Assets/img/WellspectLogo.svg) no-repeat !important;
  width: 130px;
  height: 30px;
  background-size: auto;
  background-position: inherit;
}
body .wellspect-header .responsive-logo {
  margin-top: 16px;
}
body .toplink a p {
  -webkit-font-smoothing: subpixel-antialiased;
}
body .toplink .ico {
  background: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png) no-repeat;
  background-size: 1108px 513px;
}
body .toplink.contact-us-ico .ico {
  background-position: -323px -306px;
}
body .toplink.about-us-ico .ico {
  background-position: -188px -306px;
  top: 4px;
  margin-right: 6px;
}
@media (max-width: 767px) {
  body .header .toplinks {
    top: 18px;
    position: relative;
  }
  body .header .toplinks .toplink {
    line-height: 0;
    margin-left: 5px;
  }
  body .header .toplinks .toplink a {
    width: 20px;
    height: 20px;
    padding: 0;
    display: inline-block;
  }
  body .header .toplinks .contact-us-ico .ico {
    background: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png) no-repeat;
    background-size: 1108px 513px;
    background-position: -323px -326px;
  }
  body .header .toplinks .about-us-ico .ico {
    background: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png) no-repeat;
    background-size: 1108px 513px;
    background-position: -188px -326px;
    top: 2px;
  }
}
body .wellspect-header .toggle-menu .ico {
  background: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png) no-repeat;
  background-size: 1108px 513px;
  background-position: -525px -327px;
}
@media (max-width: 767px) {
  body .header .full-screen-width {
    background-color: #F7F9FA !important;
  }
}
body .line-decoration {
  width: 100%;
  height: 20px;
  border-top: 2px solid white;
  border-left: 2px solid white;
  border-top-left-radius: 20px;
}
body #blogHeader {
  background-image: url(https://pages.wellspect.com/hubfs/Assets/img/dave.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  height: 250px;
  padding: 30px 20px;
}
@media (min-width: 1024px) {
  body #blogHeader {
    height: 350px;
  }
}
body #blogHeader h1,
body #blogHeader h2 {
  font-family: Gotham Rounded A, Gotham Rounded B, 'GothamRounded', sans-serif !important;
  font-size: 40px;
  color: #fff;
  font-weight: 100;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}
@media (min-width: 1024px) {
  body #blogHeader h1,
  body #blogHeader h2 {
    font-size: 65px;
    max-width: 50%;
  }
}
body #blogHeader > div {
  background: none;
  padding: 0;
  position: relative;
  height: 100%;
}
body #blogHeader .widget-span {
  padding: 0 !important;
}
body .sidebar .widget-type-blog_subscribe input[type=submit] {
  background: #6E1EAA;
}
body .sidebar .widget-type-blog_subscribe input[type=submit]:hover,
body .sidebar .widget-type-blog_subscribe input[type=submit]:focus {
  background: rgba(110, 30, 170, 0.7);
}
body .sidebar .widget-type-blog_subscribe input[type=submit]:active {
  background: rgba(110, 30, 170, 0.4);
  color: #6E1EAA;
}
body #hs-search-module label {
  margin: 0 0 .55em;
  font-size: 16px;
  font-weight: 400;
  color: #242424;
}
body #hs-search-module .input a {
  height: 100%;
  width: 40px;
  opacity: 0;
  z-index: 2;
}
body #hs-search-module .input::after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 16px;
  height: 16px;
  background-image: url(https://pages.wellspect.com/hubfs/Assets/img/wellspect_icons_logo_retina_sprite-1.png);
  background-size: 1108px 513px;
  display: inline-block;
  background-position: -780px -325px;
  z-index: 1;
}
body #hs-search-module .input input,
body #hs-search-module .input a {
  height: auto;
}
body .hs-form .hs-input {
  width: 100% !important;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: 5px 20px;
  line-height: 2.083333333em;
  height: auto;
}
body .hs-form .hs-input:focus {
  outline: none;
  border-color: #2B55B4;
}
body .hs-form select.hs-input {
  padding: 5px 20px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url(https://pages.wellspect.com/hubfs/Assets/img/DropdownArrow.svg);
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  box-sizing: content-box;
}
body .hs-form select.hs-input::-ms-expand {
  display: none;
}
body .hs-form-field label {
  font-weight: 800;
  color: rgba(0, 0, 0, 0.7);
}
body .hs-form .hs-error-msgs,
body .hs-form .hs-error-msgs * {
  color: #FF8200;
}
body .hs-form .hs-form-required {
  color: rgba(0, 0, 0, 0.7);
}
body .hs-form .hs-form-radio-display,
body .hs-form .hs-form-booleancheckbox-display,
body .hs-form .hs-form-booleancheckbox label,
body .hs-form .hs-form-radio label,
body .hs-form .hs-form-checkbox-display {
  padding-left: 30px;
}
body .hs-form select {
  box-sizing: border-box !important;
}
body .hs-form input[type=checkbox],
body .hs-form input[type=radio] {
  display: none !important;
}
body .hs-form input[type=checkbox] + span,
body .hs-form input[type=radio] + span {
  position: relative;
}
body .hs-form input[type=checkbox] + span::before,
body .hs-form input[type=radio] + span::before {
  content: '';
  position: absolute;
  top: 0;
  left: -30px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
}
body .hs-form input[type=checkbox]:checked + span::before,
body .hs-form input[type=radio]:checked + span::before {
  background-image: url(https://pages.wellspect.com/hubfs/Assets/img/CheckMark.svg);
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center;
}
body .hs-form input[type=radio] + span::before {
  border-radius: 50%;
}
body .hs-form input[type=radio]:checked + span::before {
  background: #fff;
}
body .hs-form input[type=radio]:checked + span::after {
  content: '';
  width: 12px;
  height: 12px;
  background: #1996FF;
  position: absolute;
  border-radius: 50%;
  top: 4px;
  left: -26px;
}
body .header .wrap {
  /*No border on the arrow link box */
}
body .header .wrap .resp-main-menu {
  overflow: hidden;
}
body .header .wrap .resp-main-menu ul {
  padding: 0;
  margin: 0;
  width: 100%;
}
body .header .wrap .resp-main-menu ul a {
  padding: 0;
  margin: 0;
  color: #A0A0A0;
  width: 100%;
  background-color: #f0f0f0;
}
body .header .wrap .resp-main-menu ul li {
  padding: 0;
  margin: 0;
  display: block;
  width: 100%;
  *zoom: 1;
}
body .header .wrap .resp-main-menu ul li:before,
body .header .wrap .resp-main-menu ul li:after {
  display: table;
  content: "";
  line-height: 0;
}
body .header .wrap .resp-main-menu ul li:after {
  clear: both;
}
body .header .wrap .resp-main-menu ul li a {
  text-decoration: none;
}
body .header .wrap .resp-main-menu ul li a:visited {
  text-decoration: none;
}
body .header .wrap .resp-main-menu ul li a:hover {
  text-decoration: none;
  text-decoration: underline;
}
body .header .wrap .resp-main-menu ul li a:active {
  text-decoration: none;
}
body .header .wrap .resp-main-menu ul li a {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: top;
  width: 100%;
}
body .header .wrap .resp-main-menu ul li a.link {
  font-size: 12px;
  display: block;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 40px;
  padding-left: 1em;
  padding-right: 30px;
}
body .header .wrap .resp-main-menu ul li a.expand-link {
  font-size: 1.2em;
  display: block;
  position: absolute;
  top: 6px;
  width: 35px;
  right: 0px;
  height: 25px;
  padding-left: 30px !important;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body .header .wrap .resp-main-menu ul li a.expand-link:after {
  border: solid transparent;
  content: " ";
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 8px;
  border-left-color: #646464;
  right: 5px;
  top: 5px;
}
body .header .wrap .resp-main-menu ul li a.expand-link.expanded:after {
  border: solid transparent;
  content: " ";
  position: absolute;
  pointer-events: none;
  border-color: rgba(255, 255, 255, 0);
  border-width: 8px;
  border-top-color: #646464;
  right: 10px;
  top: 10px;
}
body .header .wrap .resp-main-menu ul li.active > a {
  font-weight: bolder;
}
body .header .wrap .resp-main-menu ul li.has-children {
  position: relative;
}
body .header .wrap .resp-main-menu ul li.has-children > ul {
  display: none;
}
body .header .wrap .resp-main-menu ul li.has-children.expanded {
  position: relative;
}
body .header .wrap .resp-main-menu > ul {
  background: #e7e8e9;
  margin-bottom: -2px;
}
body .header .wrap .resp-main-menu li.activeSection a {
  border-left: 4px solid #0060a9;
  padding-left: 1em;
  color: #646464;
  background-color: #e7e8e9;
}
body .header .wrap .resp-main-menu > ul > li.active.expanded > a,
body .header .wrap .resp-main-menu > ul > li.active > a {
  border-left: 4px solid #bdbf00;
}
body .header .wrap .resp-main-menu > ul > li li.active > a {
  border-left: 4px solid #bdbf00;
}
body .header .wrap .resp-main-menu li.activeSection a.expand-link,
body .header .wrap .resp-main-menu > ul > li li.active > a.resp-main-menu,
body .header .wrap .resp-main-menu ul.level-1 li a.expand-link {
  border-left: 0px solid #646464;
}
body .header .wrap .resp-main-menu > ul li a.link:after {
  content: "";
  height: 1px;
  background-color: #cfd1d3;
  display: block;
  width: 110%;
  position: relative;
  top: 12px;
}
body .header .wrap .desk-main-menu ul a,
body .header .wrap .resp-main-menu ul a {
  background-color: #fff;
}
body .header .wrap .desk-main-menu li.activeSection a,
body .header .wrap .resp-main-menu li.activeSection a {
  border-left: 4px solid rgba(110, 30, 170, 0.2);
}
body .header .wrap .desk-main-menu > ul > li li.active > a,
body .header .wrap .resp-main-menu > ul > li li.active > a,
body .header .wrap .desk-main-menu > ul > li.active.expanded > a,
body .header .wrap .resp-main-menu > ul > li.active.expanded > a,
body .header .wrap .desk-main-menu > ul > li.active > a,
body .header .wrap .resp-main-menu > ul > li.active > a {
  border-left-color: #6E1EAA;
}
body .header .wrap .desk-main-menu > ul li a.link:after,
body .header .wrap .resp-main-menu > ul li a.link:after {
  top: 12px;
}
body .header .wrap .desk-main-menu li,
body .header .wrap .resp-main-menu li {
  line-height: inherit !important;
}
body .header .wrap .desk-main-menu a.link,
body .header .wrap .resp-main-menu a.link {
  border-bottom: none !important;
  line-height: inherit !important;
}
body .header .wrap .desk-main-menu ul li a.expand-link::after,
body .header .wrap .resp-main-menu ul li a.expand-link::after {
  background-image: url(https://pages.wellspect.com/hubfs/Assets/img/DropdownArrow.svg);
  border: none;
  width: 12px;
  height: 7px;
  transform: rotate(-90deg);
  right: 10px;
  top: 10px;
}
body .header .wrap .desk-main-menu ul li a.expand-link.expanded::after,
body .header .wrap .resp-main-menu ul li a.expand-link.expanded::after {
  border: none;
  transform: rotate(0);
}
body .header .wrap .desk-main-menu .expand-link:focus,
body .header .wrap .resp-main-menu .expand-link:focus {
  outline: none;
}
body .header .wrap .desk-main-menu ul a,
body .header .wrap .resp-main-menu ul a,
body .header .wrap .desk-main-menu li.activeSection a,
body .header .wrap .resp-main-menu li.activeSection a {
  color: rgba(0, 0, 0, 0.7);
}
@media (max-width: 767px) {
  body .header .toggle-menu.active {
    background: none;
  }
  body .header .toggle-menu.active::after {
    border-bottom-color: #1996FF;
  }
}
body.landing-page .header-container .widget-type-logo a,
body.landing-page .header-container .hs_cos_wrapper_type_linked_image a {
  background: url(https://pages.wellspect.com/hubfs/Assets/img/WellspectLogo.svg) no-repeat;
  height: 35px;
  width: 153px;
  background-size: contain;
  display: inline-block;
  margin-left: 15px;
  transform: translateY(7px);
}
@media (min-width: 1085px) {
  body.landing-page .header-container .widget-type-logo a,
  body.landing-page .header-container .hs_cos_wrapper_type_linked_image a {
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  body.landing-page .header-container .widget-type-logo a,
  body.landing-page .header-container .hs_cos_wrapper_type_linked_image a {
    transform: translateY(5px);
    margin-top: 10px;
  }
}
body.landing-page .header-container .widget-type-logo img,
body.landing-page .header-container .hs_cos_wrapper_type_linked_image img {
  display: none;
}
body.landing-page.blue-bar {
  border-top-color: #1996FF;
}
@media (max-width: 991px) {
  body.landing-page.blue-bar {
    border-top: none;
  }
}
body.landing-page .header-container-wrapper {
  border-bottom: none;
  background: #f7f9fa;
}
body blockquote {
  border-left: 10px solid #ccc;
  margin: 1.5em 5px;
  padding: .5em 10px;
  font-size: 1.3em;
}
body blockquote::before {
  color: #ccc;
  content: "\201C";
  font-size: 3em;
  line-height: .3em;
  vertical-align: 0;
  display: block;
  font-family: Georgia,serif;
}
body .cta_button {
  display: inline-block;
  border-radius: 15px !important;
  padding: 11px 30px !important;
  text-decoration: none !important;
  box-sizing: border-box !important;
}
body .cta_button,
body .cta_button * {
  font-size: 18px !important;
  font-weight: 600 !important;
}
body .cta_button p,
body .cta_button img {
  margin: 0;
  padding: 0;
}
body .cta_button[cta_dest_link*="play.google.com"],
body .cta_button[cta_dest_link*="itunes.apple.com"] {
  background: none !important;
  padding: 0 !important;
}
body table.product-list .product-ui__icon--add {
  background-image: url(https://pages.wellspect.com/hubfs/Assets/img/icon-add-filled.svg);
}
body table.product-list .product-ui__icon--subtract {
  background-image: url(https://pages.wellspect.com/hubfs/Assets/img/icon-subtract-filled.svg);
}
body #blogHeader h1 a:hover,
body #blogHeader h2 a:hover {
  text-decoration: none;
  color: #EBEBEB;
}
body .widget-type-linked_image img,
body .widget-type-rich_text img {
  border-top-left-radius: 30px;
}
@media (max-width: 767px) {
  body .second-row {
    display: block;
  }
  body .header .resp-main-menu ul li.has-children.expanded {
    background: transparent;
  }
  body .resp-main-menu li.activeSection a {
    background-color: #e7e8e9;
  }
  body .header .resp-main-menu ul.level-0 li > a {
    background: #fff;
  }
}
body {
  overflow-x: hidden;
}
.full-hero {
  min-height: 200px;
  position: relative;
  overflow: hidden;
  margin-bottom: 55px;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
}
@media (min-width: 768px) {
  .full-hero {
    margin-top: -40px;
  }
}
.full-hero * {
  box-sizing: inherit;
}
.full-hero__innerwrapper {
  max-width: 1024px;
  margin: 0 auto;
  width: 100%;
  position: relative;
  padding: 0 20px 50px 0;
}
@media (min-width: 1024px) {
  .full-hero__innerwrapper {
    padding: 0 10px 55px;
  }
}
.full-hero .line-decoration {
  margin-top: 25px;
}
@media (min-width: 1024px) {
  .full-hero .line-decoration {
    margin-top: 30px;
  }
}
.full-hero__textwrapper {
  margin-left: 20px;
}
@media (min-width: 768px) {
  .full-hero__textwrapper {
    max-width: 50%;
  }
}
.full-hero__text {
  margin: 35px 0 40px 0;
  font-family: Gotham Rounded A, Gotham Rounded B, 'GothamRounded', sans-serif !important;
  font-size: 40px;
  line-height: 1.2em;
  font-weight: 100;
  text-transform: uppercase;
  color: #fff;
}
@media (min-width: 1024px) {
  .full-hero__text {
    font-size: 65px;
  }
}
.full-hero__image {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}
.full-hero .button {
  margin-left: 20px;
}
@media (max-width: 500px) {
  .full-hero .button {
    bottom: -60px;
    position: absolute;
    width: 100%;
    margin: 0;
    background: #1996FF;
    border: none;
    text-align: center;
  }
  .full-hero .button:hover,
  .full-hero .button:focus {
    color: #fff !important;
    background: rgba(25, 150, 255, 0.7);
  }
  .full-hero .button:active {
    background: rgba(25, 150, 255, 0.4);
    color: #1996FF !important;
  }
}
